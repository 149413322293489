import React from "react";
import SEO from "../../components/seo";
import Blog from "../../components/Blog";
import Par from "../../components/Par";
import H1 from "../../components/h1";
import SiteLink from "../../components/SiteLink";

const title = "Lactase Deficiency (lactose intolerance)";

// Download link - http://www.fbrennangastro.com.au/downloads/gesalactasedeficiency.pdf

export default () => (
	<Blog title={title}>
		<SEO
			title={title}
			keywords={[title, "IBS", "Lactase enzyme", "Genetics lactase deficiency"]}
			type="article"
			description="Lactose intolerance - the reasons behind it, the symptoms that it causes and how to control it"
		/>
		<H1>{title}</H1>
		<Par>
			Lactose intolerance is the inability to digest lactose. Lactose is a type of sugar found in milk and other dairy
			products.
		</Par>
		<Par>
			Lactose intolerance happens when the small intestine does not make enough of the enzyme lactase. Enzymes help the
			body absorb foods. Not having enough lactase is called lactase deficiency.
		</Par>
		<Par>
			Lactase deficiency is more common in people with Aboriginal, Asian, African, Middle Eastern or Mediterranean
			ancestry. It is rare for Caucasians to develop it.
		</Par>
		<Par>
			Many people with lactase deficiency can usually tolerate some lactose with minimal symptoms. Symptoms are often
			confused with <SiteLink to="/articles/irritable-bowel-syndrome-IBS">irritable bowel syndrome (IBS)</SiteLink> and
			can include:
		</Par>
		<ul>
			<li>Abdominal pain</li>
			<li>Abdominal swelling</li>
			<li>Flatulence</li>
			<li>Diarrhoea</li>
		</ul>
		<Par>
			Symptoms usually go away when you remove milk products or other products containing lactose from the diet.
		</Par>
	</Blog>
);
